import moment from "moment";

function calculateDaysIncludingEnds(startDate, endDate) {
  // Parse the start and end dates using Moment.js
  const start = moment(startDate, "YYYY-MM-DD");
  const end = moment(endDate, "YYYY-MM-DD");

  // Calculate the difference in days
  const days = end.diff(start, "days");

  // Add 1 to include both the start and end dates
  return days + 1;
}
function calculateAdjustedEndDate(
  startDate = moment().format("YYYY-MM-DD"),
  workingDays = [1, 2, 3, 4, 5],
  requiredWeekdays = 2,
  pauses = [],
  holidays = []
) {
  // Parse the start date using Moment.js
  let currentMoment = moment(startDate);

  // Convert pause periods and holidays to Moment.js objects
  let pausePeriods = pauses.map((pause) => ({
    start: moment(pause.start),
    end: moment(pause.end),
  }));
  let holidayMoments = holidays.map((holiday) => moment(holiday));

  // Function to check if a day is a working day
  const isWorkingDay = (date) => {
    return workingDays.includes(date.day()); // Use Moment.js 'day()' method
  };

  // Function to check if a day is within any of the pause periods
  const isPausedDay = (date) => {
    return (
      pausePeriods.length > 0 &&
      pausePeriods.some(
        (pause) =>
          date.isSameOrAfter(pause.start) && date.isSameOrBefore(pause.end)
      )
    );
  };

  // Function to check if a day is a holiday
  const isHoliday = (date) => {
    return (
      holidayMoments.length > 0 &&
      holidayMoments.some((holiday) => date.isSame(holiday, "day"))
    );
  };

  // Calculate the new end date by counting valid weekdays
  let validDaysCounted = 0;
  if (requiredWeekdays < 1) {
    requiredWeekdays = 1;
  }
  while (validDaysCounted < requiredWeekdays) {
    if (
      isWorkingDay(currentMoment) &&
      !isPausedDay(currentMoment) &&
      !isHoliday(currentMoment)
    ) {
      validDaysCounted++;
    }
    currentMoment.add(1, "days"); // Increment the day using Moment.js
  }

  // Moment object needs to be formatted to return a date string
  return currentMoment.subtract(1, "days").format("YYYY-MM-DD"); // Adjusting back one day
}

export { calculateAdjustedEndDate, calculateDaysIncludingEnds };
