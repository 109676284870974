import { Box, Container, Link, Paper, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "40px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
        },
      },
    },
  },
});

const RefundPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box textAlign="center" mb={4}>
            <Typography variant="h4" gutterBottom color="primary">
              DT Meals Refund Policy
            </Typography>
          </Box>
          <Typography variant="body1" paragraph>
            Welcome to DT Meals! Our platform connects customers with a variety
            of sellers and merchants who offer meal subscriptions and orders.
            Please read our refund policy carefully to understand the terms
            under which refunds may be issued.
          </Typography>

          <Box mt={3}>
            <Typography variant="h5" gutterBottom color="secondary">
              1. Payment Handling
            </Typography>
            <Typography variant="body1" paragraph>
              Payments for all orders and subscriptions made through DT Meals
              are processed directly by the respective sellers or merchants. DT
              Meals does not handle or process any payments.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h5" gutterBottom color="secondary">
              2. Refunds
            </Typography>
            <Typography variant="body1" paragraph>
              As DT Meals is a platform that facilitates transactions between
              customers and sellers or merchants, we do not have the authority
              to issue refunds. Any requests for refunds must be directed to the
              seller or merchant from whom you made the purchase.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h5" gutterBottom color="secondary">
              3. Contacting Sellers or Merchants
            </Typography>
            <Typography variant="body1" paragraph>
              If you are dissatisfied with a product or service and wish to
              request a refund, please contact the seller or merchant directly.
              You can find their contact information on the order confirmation
              email or within your account on DT Meals.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h5" gutterBottom color="secondary">
              4. Seller or Merchant Refund Policies
            </Typography>
            <Typography variant="body1" paragraph>
              Each seller or merchant on DT Meals may have their own refund
              policies. We encourage you to review the refund policy of the
              specific seller or merchant before making a purchase. DT Meals is
              not responsible for enforcing the refund policies of individual
              sellers or merchants.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h5" gutterBottom color="secondary">
              5. Dispute Resolution
            </Typography>
            <Typography variant="body1" paragraph>
              In the event of a dispute regarding a refund, we recommend
              communicating directly with the seller or merchant to resolve the
              issue. DT Meals is not responsible for mediating disputes between
              customers and sellers or merchants.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h5" gutterBottom color="secondary">
              6. Policy Changes
            </Typography>
            <Typography variant="body1" paragraph>
              DT Meals reserves the right to update or modify this refund policy
              at any time. Any changes will be posted on our website, and we
              encourage you to review this policy periodically.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="body1" paragraph>
              By using DT Meals, you acknowledge that you have read and agree to
              this refund policy. If you have any questions or concerns, please
              contact our customer support team at{" "}
              <Link href="mailto:support@dtmeals.com" color="primary">
                support@dtmeals.com
              </Link>
              .
            </Typography>
          </Box>

          <Box mt={4} textAlign="center">
            <Typography variant="body1">
              Thank you for choosing DT Meals!
            </Typography>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default RefundPolicy;
