import { Grid, TextField, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import * as React from "react";
import { Button } from "react-bootstrap";
import { getMenuBySelId } from "../api/menu";
import { calculateAdjustedEndDate } from "../api/resources";
import { updateSubscription } from "../api/subscription";

function DatePickerComponent({ orderValues }) {
  const [pauseStartDate, setPauseStartDate] = React.useState(moment());
  const [pauseEndDate, setPauseEndDate] = React.useState(moment());
  const [calEndDate, setCalEndDate] = React.useState(orderValues.calEndDate);
  const [maxPausesReached, setMaxPausesReached] = React.useState(false);
  let pauses = orderValues?.pauses?.length > 0 ? orderValues?.pauses : [];
  const getMaxPauses = async () => {
    const {
      data: { maxPauses = 1000 },
    } = await getMenuBySelId(orderValues.SelID);

    if (maxPauses > 0) {
      setMaxPausesReached(orderValues?.pauses?.length >= maxPauses);
    }
  };
  React.useEffect(() => {
    getMaxPauses();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setCalEndDate(
      calculateAdjustedEndDate(
        orderValues.sDate,
        orderValues.selDays,
        orderValues.requiredWeekdays,
        [
          ...pauses,
          ...[
            {
              start: moment(pauseStartDate).format("YYYY-MM-DD"),
              end: moment(pauseEndDate).format("YYYY-MM-DD"),
            },
          ],
        ],
        []
      )
    );
  }, [
    getMaxPauses,
    orderValues.pauses,
    orderValues.requiredWeekdays,
    orderValues.sDate,
    orderValues.selDays,
    pauseEndDate,
    pauseStartDate,
    pauses,
  ]);
  return (
    <>
      {maxPausesReached ? (
        <Typography>
          You have reached maximum pauses for this subscription
        </Typography>
      ) : (
        <Grid item xs={12} container style={{ justifyContent: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ margin: "10px" }}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="yyyy-MM-dd"
                value={pauseStartDate}
                onChange={(newValue) => {
                  setPauseStartDate(newValue);
                  // Ensure the end date is not before the start date
                  if (pauseEndDate && newValue && newValue > pauseEndDate) {
                    setPauseEndDate(newValue); // Set end date same as start date if it falls behind
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <DesktopDatePicker
                label="End Date"
                inputFormat="yyyy-MM-dd"
                value={pauseEndDate}
                minDate={pauseStartDate} // Allow the same day as start date
                onChange={setPauseEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>
          <Typography>
            Do you want to add pause period from{" "}
            <span style={{ fontWeight: "bolder" }}>
              {moment(pauseStartDate).format("YYYY-MM-DD")}{" "}
            </span>
            to{" "}
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              {moment(pauseEndDate).format("YYYY-MM-DD")}
            </span>
            ? <br />
            New end date will be{" "}
            <span style={{ fontWeight: "bolder" }}>
              {moment(calEndDate).format("YYYY-MM-DD")}
            </span>
          </Typography>
          <Grid item xs={12} container style={{ justifyContent: "center" }}>
            <Typography fontWeight={"bolder"} style={{ marginTop: 10 }}>
              Note - This action can't be undone
            </Typography>
          </Grid>
          <Grid item xs={12} container style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              style={{
                margin: "20px",
                backgroundColor: "#9f6ba0",
                fontWeight: "bold",
                color: "white",
              }}
              onClick={async () => {
                await updateSubscription({
                  _id: orderValues._id,
                  SelID: orderValues.SelID,
                  pauses: [
                    ...pauses,
                    ...[
                      {
                        start: moment(pauseStartDate).format("YYYY-MM-DD"),
                        end: moment(pauseEndDate).format("YYYY-MM-DD"),
                      },
                    ],
                  ],
                  calEndDate: calEndDate,
                  renewalDate: moment(calEndDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                  sub_id: orderValues.sub_id,
                });

                window.location.href = "/orders";
              }}
            >
              Add Pause
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default DatePickerComponent;
