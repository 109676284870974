import axios from "axios";
import api from "./config";
import { encryptPayload } from "./encrypt";

const createSubscription = async (values) => {
  const result = await axios.post(
    api + "/seller/create/subscription",
    encryptPayload(values)
  );
  return result;
};

const pendingSubscription = async (values) => {
  const result = await axios.post(
    api + "/new/subscription",
    encryptPayload(values)
  );
  return result;
};

const getSubscriptions = async (values) => {
  const result = await axios.post(
    api + "/subscriptions/any",
    encryptPayload(values)
  );
  return result;
};

const getPendingSubscriptions = async (values) => {
  const result = await axios.post(
    api + "/pending/subscriptions/any",
    encryptPayload(values)
  );
  return result;
};

const paymentMethodUpdate = async (values) => {
  const result = await axios.post(
    api + "/payment/method/update",
    encryptPayload(values)
  );
  return result;
};

const cancelSubscription = async (values) => {
  const result = await axios.post(
    api + "/cancel/subscription",
    encryptPayload(values)
  );
  return result;
};

const resumeSubscription = async (values) => {
  const result = await axios.post(
    api + "/resume/subscription",
    encryptPayload(values)
  );
  return result;
};

const updateSubscription = async (values) => {
  const result = await axios.post(
    api + "/seller/subscriptions/update",
    encryptPayload(values)
  );
  return result;
};

export {
  cancelSubscription,
  createSubscription,
  getPendingSubscriptions,
  getSubscriptions,
  paymentMethodUpdate,
  pendingSubscription,
  resumeSubscription,
  updateSubscription,
};
