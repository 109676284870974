import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DataContext from "../api/context";
import Loading from "./../shared/Loading";
import Failed from "./Failed";
import Home from "./Home";
import MenuSelection from "./MenuSelection";
import OrdersList from "./OrdersList";
import ProtectedRoute from "./ProtectedRoute";
import RefundPolicy from "./RefundPolicy";
import Search from "./Search";
import Success from "./Success";

const ServiceRoutes = () => {
  const { user } = useContext(DataContext);
  return (
    <Routes>
      <Route path="/menu/:id" element={<MenuSelection />} />
      <Route path="/services/:city" element={<Search />} />
      <Route path="/loading" element={<Loading />} />
      <Route
        path="/orders"
        element={
          <ProtectedRoute user={user}>
            <OrdersList />
          </ProtectedRoute>
        }
      />
      <Route path="/success" element={<Success />} />
      <Route path="/failed" element={<Failed />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/:code" element={<MenuSelection />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default ServiceRoutes;
