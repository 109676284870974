import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const WeeklyMenu = ({ weeklyMenu, logo, tname }) => {
  const [open, setOpen] = useState(false);
  // Array of weekly menu items
  const { menu, startDate, endDate } = weeklyMenu;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        style={{
          backgroundColor: "#9f6ba0",
          color: "white",
          padding: "7px",
          fontSize: "20",
          cursor: "pointer",
          textAlign: "center",
          borderRadius: 20,
          marginInline: 10,
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        }}
      >
        <Typography variant="h6">Weekly Menu</Typography>
        <Typography variant="body2">Click to view</Typography>
      </div>

      <Dialog open={open} onClose={handleClose} style={{ minWidth: "600px" }}>
        <DialogTitle>
          <Grid
            item
            xs={12}
            container
            style={{ marginTop: 10, marginBottom: 20 }}
            alignItems="center"
          >
            {logo !== "" && (
              <img
                src={logo}
                alt="orderNow"
                style={{
                  width: 40,
                  height: 40,
                }}
              />
            )}
            <Typography
              style={{ fontSize: 35, fontWeight: "bolder", marginLeft: 15 }}
            >
              {tname}
            </Typography>
          </Grid>

          <span
            style={{
              color: "#9f6ba0",
              marginBlock: 10,
              marginTop: 30,
              fontWeight: "bold",
            }}
          >
            Weekly Menu ({startDate} - {endDate})
          </span>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} style={{ minWidth: "500px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: "#9f6ba0", fontWeight: "bold" }}>
                    Day
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Delicious Menu
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(menu).map(
                  ([day, menu], index) =>
                    menu && (
                      <TableRow key={index}>
                        <TableCell style={{ color: "#9f6ba0" }}>
                          {day}
                        </TableCell>
                        <TableCell>{menu}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#9f6ba0" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WeeklyMenu;
