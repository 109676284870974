import { Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../api/context";
import { getPastOrders, getPendingOrders } from "../api/order";
import Layout from "../shared/Layout";
import SubscriptionsList from "./SubscriptionsList";
const OrdersList = () => {
  const { user, setLoading } = useContext(DataContext);
  const [orders, setOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [systemExtras, setSystemExtras] = useState([]);
  const getOrders = async () => {
    if (user.CusID) {
      setLoading(true);
      const { data } = await axios.get(
        "https://singlamanik12.github.io/tiffin-conf/extras.json"
      );
      setSystemExtras(Object.keys(data));
      setPendingOrders(await getPendingOrders({ CusID: user.CusID }));
      setOrders(await getPastOrders(user.CusID));
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrders();
  }, [user]);
  return (
    <>
      <SubscriptionsList />
      <Layout>
        <Grid item container direction="row" style={{ padding: "8px" }}>
          {/* {pendingOrders.length > 0 && (
          <Typography
            style={{ marginBottom: 20, fontSize: "20px", fontWeight: "bold" }}
          >
            Pending Orders
          </Typography>
        )} */}
          <Typography
            variant="h4"
            style={{ fontWeight: "bolder", marginTop: 30 }}
          >
            Orders
          </Typography>
          {pendingOrders?.length > 0 &&
            pendingOrders.map((order, index) => (
              <>
                {" "}
                <Paper
                  key={index}
                  elevation={3}
                  style={{ width: "100%", padding: 25, marginTop: 20 }}
                >
                  <Grid item xs={12} container key={index}>
                    <Grid
                      item
                      xs={6}
                      container
                      direction="column"
                      style={{ marginTop: 20 }}
                    >
                      <Typography>
                        Order ID : {order?._id?.slice(-5)}
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {order?.tname}
                      </Typography>
                      {order?.selPlan?.planName && (
                        <Typography>{order?.prodType?.prodName}</Typography>
                      )}
                      {order?.selPlan?.planName && (
                        <Typography>{order?.selPlan?.planName}</Typography>
                      )}

                      <Typography>{order.menuOpt.menuType}</Typography>

                      {/* {order?.previewData?.rrOpt && (
                    <Typography>
                      {order.rrOpt?.roti > 0 ? order?.rrOpt?.roti : "no"} roti &{" "}
                      {order.rrOpt?.rice > 0 ? order?.rrOpt?.rice : "no"} rice
                    </Typography>
                  )} */}

                      {/* {systemExtras?.map((key) => {
                    return (
                      parseInt(order[key]) > 0 && (
                        <Typography key={key}>
                          {order[key]}
                          {" Extra " + _.capitalize(key.substring(1))}
                        </Typography>
                      )
                    );
                  })} */}

                      <Typography>
                        {order?.sDate + " - " + order?.eDate}
                      </Typography>

                      {order?.request && (
                        <Typography>
                          <span>Additional Request</span> - {order?.request}
                        </Typography>
                      )}

                      {/* {!!order?.previewData?.selPlan?.days && (
                <Grid style={{ backgroundColor: "whitesmoke" }}>
                  <Typography>
                    Ends on
                    <Typography style={{ fontWeight: "bolder", fontSize: 20 }}>
                      {moment(order?.sDate, "YYYY-MM-DD")
                        .add(order?.previewData?.selPlan?.days, "days")
                        .format("YYYY-MM-DD")}
                    </Typography>
                  </Typography>
                </Grid>
              )} */}
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="right"
                        style={{ marginTop: 40 }}
                      >
                        <Typography>
                          Cost{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              marginLeft: 20,
                              fontSize: 17,
                            }}
                          >
                            <span style={{ marginRight: 2 }}>C$</span>
                            {order?.cost}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="right"
                        style={{ marginTop: 10 }}
                      >
                        <Typography>
                          Tax{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              marginLeft: 20,
                              fontSize: 17,
                            }}
                          >
                            <span style={{ marginRight: 2 }}>C$</span>
                            {order?.tax}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container justifyContent="right">
                        <div
                          style={{
                            width: "112px",
                            height: "7px",
                            position: "relative",
                            borderBottom: "1px solid #000000",
                          }}
                        ></div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="right"
                        style={{ marginTop: 10 }}
                      >
                        <Typography>
                          Total{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              marginLeft: 20,
                              fontSize: 25,
                            }}
                          >
                            <span style={{ marginRight: 2 }}>C$</span>
                            {order?.totalPrice}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ))}
          {/* {orders?.length > 0 && (
          <Grid item xs={12} style={{ marginBlock: 5 }}>
            <Typography
              style={{ marginBlock: 20, fontSize: "20px", fontWeight: "bold" }}
            >
              Past Orders
            </Typography>
          </Grid>
        )} */}
          {orders?.length > 0 &&
            orders.map((order, index) => (
              <>
                {" "}
                <Paper
                  key={index}
                  elevation={3}
                  style={{ width: "100%", padding: 25, marginTop: 20 }}
                >
                  <Grid item xs={12} container key={index}>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="column"
                      style={{ marginTop: 20 }}
                    >
                      <Typography>
                        Order ID : {order?._id?.slice(-5)}
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {order?.previewData?.tname}
                      </Typography>
                      {order?.previewData?.selPlan?.planName && (
                        <Typography>
                          {order?.previewData?.selPlan?.planName}
                        </Typography>
                      )}

                      <Typography>
                        {order?.previewData?.menuOpt.menuType}
                      </Typography>

                      {/* {order?.previewData?.rrOpt && (
                    <Typography>
                      {order?.previewData?.rrOpt?.roti > 0
                        ? order?.previewData?.rrOpt?.roti
                        : "no"}{" "}
                      roti &{" "}
                      {order?.previewData?.rrOpt?.rice > 0
                        ? order?.previewData?.rrOpt?.rice
                        : "no"}{" "}
                      rice
                    </Typography>
                  )} */}

                      {/* {systemExtras?.map((key) => {
                    return (
                      parseInt(order[key]) > 0 && (
                        <Typography key={key}>
                          {order[key]}
                          {" Extra " + _.capitalize(key.substring(1))}
                        </Typography>
                      )
                    );
                  })} */}
                      {!!order?.previewData?.selPlan?.days && (
                        <Typography>
                          {order?.sDate + " - " + order?.eDate}
                        </Typography>
                      )}

                      {order?.request && (
                        <Typography>
                          <span>Additional Request</span> - {order?.request}
                        </Typography>
                      )}

                      {/* {!!order?.previewData?.selPlan?.days && (
                <Grid style={{ backgroundColor: "whitesmoke" }}>
                  <Typography>
                    Ends on
                    <Typography style={{ fontWeight: "bolder", fontSize: 20 }}>
                      {moment(order?.sDate, "YYYY-MM-DD")
                        .add(order?.previewData?.selPlan?.days, "days")
                        .format("YYYY-MM-DD")}
                    </Typography>
                  </Typography>
                </Grid>
              )} */}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12} container style={{ marginTop: 40 }}>
                        <Typography>
                          Cost{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              marginLeft: 20,
                              fontSize: 17,
                            }}
                          >
                            <span style={{ marginRight: 2 }}>C$</span>
                            {order?.previewData?.cost}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container style={{ marginTop: 10 }}>
                        <Typography>
                          Tax{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              marginLeft: 20,
                              fontSize: 17,
                            }}
                          >
                            <span style={{ marginRight: 2 }}>C$</span>
                            {order?.previewData?.tax}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container>
                        <div
                          style={{
                            width: "112px",
                            height: "7px",
                            position: "relative",
                            borderBottom: "1px solid #000000",
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs={12} container style={{ marginTop: 10 }}>
                        <Typography>
                          Total{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              marginLeft: 20,
                              fontSize: 25,
                            }}
                          >
                            <span style={{ marginRight: 2 }}>C$</span>
                            {order?.previewData?.totalPrice}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ))}
        </Grid>
      </Layout>
    </>
  );
};

export default OrdersList;
