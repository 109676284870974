import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../api/context";
import {
  getPendingSubscriptions,
  getSubscriptions,
  paymentMethodUpdate,
} from "../api/subscription";
import Layout from "../shared/Layout";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import DatePickerUI from "./DatePickerUI";
import ResumeSubscriptionDialog from "./ResumeSubscriptionDialog";
const SubscriptionsList = () => {
  const { user, setLoading } = useContext(DataContext);
  const [orders, setOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [systemExtras, setSystemExtras] = useState([]);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [resumeOpen, setResumeOpen] = useState(false);

  const [selID, setSelID] = useState();
  const [subId, setSubId] = useState();
  const getOrders = async () => {
    if (user.CusID) {
      setLoading(true);
      const { data } = await axios.get(
        "https://singlamanik12.github.io/tiffin-conf/extras.json"
      );
      setSystemExtras(Object.keys(data));
      const { data: pendingSubs } = await getPendingSubscriptions({
        CusID: user.CusID,
        isPaid: true,
      });
      setPendingOrders(pendingSubs);
      const { data: subs } = await getSubscriptions({
        CusID: user.CusID,
        isPaid: true,
      });
      setOrders(subs);
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrders();
  }, [user]);
  return (
    <Layout>
      <Grid item container direction="row" style={{ padding: "8px" }}>
        <CancelSubscriptionDialog
          open={cancelOpen}
          setOpen={setCancelOpen}
          subId={subId}
          SelID={selID}
        />
        <ResumeSubscriptionDialog
          open={resumeOpen}
          setOpen={setResumeOpen}
          subId={subId}
          SelID={selID}
        />
        {/* {pendingOrders.length > 0 && (
          <Typography
            style={{ marginBottom: 20, fontSize: "20px", fontWeight: "bold" }}
          >
            Pending Orders
          </Typography>
        )} */}
        <Typography
          variant="h4"
          style={{ fontWeight: "bolder", marginTop: 20 }}
        >
          Subscriptions
        </Typography>
        {pendingOrders?.length > 0 &&
          pendingOrders.map((order, index) => (
            <>
              {" "}
              <Paper
                key={index}
                elevation={3}
                style={{ width: "100%", padding: 25, marginTop: 20 }}
              >
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    style={{ marginTop: 20 }}
                  >
                    {moment(order?.renewalDate).isSameOrAfter(
                      moment().startOf("day"),
                      "day"
                    ) && (
                      <Typography
                        style={{ color: "#4CAF50", fontWeight: "bolder" }}
                      >
                        Active
                      </Typography>
                    )}
                    <Typography>Order ID : {order?._id?.slice(-5)}</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.tname}
                    </Typography>
                    {order?.selPlan?.planName && (
                      <Typography>{order?.prodType?.prodName}</Typography>
                    )}
                    {order?.selPlan?.planName && (
                      <Typography>{order?.selPlan?.planName}</Typography>
                    )}

                    <Typography>{order.menuOpt.menuType}</Typography>

                    {/* {order?.previewData?.rrOpt && (
                      <Typography>
                        {order.rrOpt?.roti > 0 ? order?.rrOpt?.roti : "no"} roti
                        & {order.rrOpt?.rice > 0 ? order?.rrOpt?.rice : "no"}{" "}
                        rice
                      </Typography>
                    )} */}

                    {/* {systemExtras?.map((key) => {
                      return (
                        parseInt(order[key]) > 0 && (
                          <Typography key={key}>
                            {order[key]}
                            {" Extra " + _.capitalize(key.substring(1))}
                          </Typography>
                        )
                      );
                    })} */}

                    <Typography>
                      Cycle - {order?.sDate + " - " + order?.eDate}
                    </Typography>

                    <Typography>
                      Next Cycle from - {order?.renewalDate}
                    </Typography>

                    {order?.request && (
                      <Typography>
                        <span>Additional Request</span> - {order?.request}
                      </Typography>
                    )}

                    {/* {!!order?.previewData?.selPlan?.days && (
                <Grid style={{ backgroundColor: "whitesmoke" }}>
                  <Typography>
                    Ends on
                    <Typography style={{ fontWeight: "bolder", fontSize: 20 }}>
                      {moment(order?.sDate, "YYYY-MM-DD")
                        .add(order?.previewData?.selPlan?.days, "days")
                        .format("YYYY-MM-DD")}
                    </Typography>
                  </Typography>
                </Grid>
              )} */}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} container style={{ marginTop: 40 }}>
                      <Typography>
                        Cost{" "}
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: 20,
                            fontSize: 17,
                          }}
                        >
                          <span style={{ marginRight: 2 }}>C$</span>
                          {order?.cost}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container style={{ marginTop: 10 }}>
                      <Typography>
                        Tax{" "}
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: 20,
                            fontSize: 17,
                          }}
                        >
                          <span style={{ marginRight: 2 }}>C$</span>
                          {order?.tax}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container>
                      <div
                        style={{
                          width: "112px",
                          height: "7px",
                          position: "relative",
                          borderBottom: "1px solid #000000",
                        }}
                      ></div>
                    </Grid>
                    <Grid item xs={12} container style={{ marginTop: 10 }}>
                      <Typography>
                        Total{" "}
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: 20,
                            fontSize: 25,
                          }}
                        >
                          <span style={{ marginRight: 2 }}>C$</span>
                          {order?.totalPrice}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Button
                    variant="text"
                    style={{ color: "#9F6BA0" }}
                    onClick={async () => {
                      const {
                        data: { url },
                      } = await paymentMethodUpdate({
                        stripeCusID:
                          order?.completedCheckoutSession?.data?.object
                            ?.customer,
                        SelID: order?.SelID,
                      });
                      window.location.href = url;
                    }}
                  >
                    Update Payment Method
                  </Button>
                </Grid>
              </Paper>
              <Grid item xs={12} style={{ marginBlock: 5 }}>
                <Divider style={{ color: "black", height: "1px" }} />
              </Grid>
            </>
          ))}
        {/* {orders?.length > 0 && (
          <Grid item xs={12} style={{ marginBlock: 5 }}>
            <Typography
              style={{ marginBlock: 20, fontSize: "20px", fontWeight: "bold" }}
            >
              Past Orders
            </Typography>
          </Grid>
        )} */}
        {orders?.length > 0 &&
          orders.map((order, index) => (
            <>
              {" "}
              <Paper
                key={index}
                elevation={3}
                style={{ width: "100%", padding: 25, marginTop: 20 }}
              >
                <Grid item xs={12} container style={{ marginBottom: 40 }}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      borderWidth: "1px",
                      border: 1,
                      display: "inline-flex",
                    }}
                  >
                    {order?.status === "active" && (
                      <Typography
                        style={{ color: "#4CAF50", fontWeight: "bolder" }}
                      >
                        Active
                      </Typography>
                    )}
                    <Typography>Order ID : {order?._id?.slice(-5)}</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.previewData?.tname}
                    </Typography>
                    {order?.previewData?.selPlan?.planName && (
                      <Typography>
                        {order?.previewData?.selPlan?.planName}
                      </Typography>
                    )}

                    <Typography>
                      {order?.previewData?.menuOpt.menuType}
                    </Typography>

                    {/* {order?.previewData?.rrOpt && (
                      <Typography>
                        {order?.previewData?.rrOpt?.roti > 0
                          ? order?.previewData?.rrOpt?.roti
                          : "no"}{" "}
                        roti &{" "}
                        {order?.previewData?.rrOpt?.rice > 0
                          ? order?.previewData?.rrOpt?.rice
                          : "no"}{" "}
                        rice
                      </Typography>
                    )} */}

                    {/* {systemExtras?.map((key) => {
                      return (
                        parseInt(order[key]) > 0 && (
                          <Typography key={key}>
                            {order[key]}
                            {" Extra " + _.capitalize(key.substring(1))}
                          </Typography>
                        )
                      );
                    })} */}
                    {!!order?.previewData?.selPlan?.days && (
                      <Typography>
                        Cycle - {order?.sDate + " - " + order?.calEndDate}
                      </Typography>
                    )}

                    <Typography>
                      Next Cycle from - {order?.renewalDate}
                    </Typography>

                    {order?.request && (
                      <Typography>
                        <span>Additional Request</span> - {order?.request}
                      </Typography>
                    )}

                    <Typography style={{ fontWeight: "bolder" }}>
                      Pauses
                    </Typography>
                    {order?.pauses?.map((pause) => (
                      <Typography>
                        {pause?.start} - {pause?.end}
                      </Typography>
                    ))}
                    {/* {!!order?.previewData?.selPlan?.days && (
                <Grid style={{ backgroundColor: "whitesmoke" }}>
                  <Typography>
                    Ends on
                    <Typography style={{ fontWeight: "bolder", fontSize: 20 }}>
                      {moment(order?.sDate, "YYYY-MM-DD")
                        .add(order?.previewData?.selPlan?.days, "days")
                        .format("YYYY-MM-DD")}
                    </Typography>
                  </Typography>
                </Grid>
              )} */}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      container
                      // justifyContent="right"
                      style={{ marginTop: 40 }}
                    >
                      <Typography>
                        Cost{" "}
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: 20,
                            fontSize: 17,
                          }}
                        >
                          <span style={{ marginRight: 2 }}>C$</span>
                          {order?.previewData?.cost}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      // justifyContent="right"
                      style={{ marginTop: 10 }}
                    >
                      <Typography>
                        Tax{" "}
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: 20,
                            fontSize: 17,
                          }}
                        >
                          <span style={{ marginRight: 2 }}>C$</span>
                          {order?.previewData?.tax}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container>
                      <div
                        style={{
                          width: "112px",
                          height: "7px",
                          position: "relative",
                          borderBottom: "1px solid #000000",
                        }}
                      ></div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      // justifyContent="right"
                      style={{ marginTop: 10 }}
                    >
                      <Typography>
                        Total{" "}
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: 20,
                            fontSize: 25,
                          }}
                        >
                          <span style={{ marginRight: 2 }}>C$</span>
                          {order?.previewData?.totalPrice}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {order?.status === "active" && (
                  <>
                    <Grid item xs={12} container>
                      <DatePickerUI orderValues={order} />
                    </Grid>
                    <Grid item xs={12} container>
                      <Button
                        variant="text"
                        style={{ color: "#9F6BA0" }}
                        onClick={async () => {
                          const {
                            data: { url },
                          } = await paymentMethodUpdate({
                            stripeCusID:
                              order?.completedCheckoutSession?.data?.object
                                ?.customer,
                            SelID: order?.SelID,
                          });
                          window.location.href = url;
                        }}
                      >
                        Update Payment Method
                      </Button>
                      {!!order?.cancel_at_period_end ? (
                        <Button
                          variant="text"
                          style={{ color: "red" }}
                          onClick={async () => {
                            setResumeOpen(true);
                            setSelID(order?.SelID);
                            setSubId(order?._id);
                          }}
                        >
                          Resume Subscription
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          style={{ color: "red" }}
                          onClick={async () => {
                            setCancelOpen(true);
                            setSelID(order?.SelID);
                            setSubId(order?._id);
                          }}
                        >
                          Cancel Subscription
                        </Button>
                      )}
                    </Grid>
                  </>
                )}
              </Paper>
              <Grid item xs={12} style={{ marginBlock: 5 }}>
                <Divider style={{ color: "black", height: "1px" }} />
              </Grid>
            </>
          ))}
      </Grid>
    </Layout>
  );
};

export default SubscriptionsList;
